@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i&display=swap');
@import url('https://fonts.googleapis.com/css?family=La+Belle+Aurore');
$default: #a2a2a2;
$defaultComp: #f4f4f4;
$defaultAccent: #FFB032;
$defaultText: #a2a2a2;
$defaultBackground: #212121;
$secondaryText: #a2a2a2;

html {
    font-size: 1em;
}
body {
    color: $defaultText;
    background: $defaultBackground;
    font-family: 'Open Sans', sans-serif;
    margin: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}
h1 {
    display: none;
}
h2 {
    margin-top: 0px;
    margin-bottom: 2em;
    text-align: center;
    font-weight: normal;
    font-size: 1.7em;
    color: $defaultComp;
}
hr {
    height: 0px;
    border: none;
    border-top: 1px solid $secondaryText;
    margin-top: 1.3em;
    margin-bottom: 1.3em;
}
#content {
    position: relative;
    margin-top: 1.3em;
    margin-bottom: 2.6em;
}

.block {
    position: relative;
    padding: 1.3em;
}
.nowrap {
    white-space: nowrap;
}
.code-block {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
pre.code {
    
    padding: 15px;
    color: $defaultAccent;
    color: rgba(255, 176, 50, 0.06);
    position: absolute;
    padding: 0;
    margin: 0;
    z-index: -5;
    font-size: 3em;
    > code {
        @extend .block;
        font-family: 'La Belle Aurore', cursive;
        line-height: 1.2em;
        display: none;
        position: relative;
   
        padding: 0;
    }
    &.code1 {
        left: -50px;
        top: -12px;
        display: block;
    }
    &.code2 {
        left: 100%;
        top: 100%;
        margin-top: -440px;
        margin-left: -590px;
        font-size: 3em;

    }
    &.code5 {
        font-size: 6em;
    }
}

.container {
    max-width: 50em;
    margin: 0 auto;
    padding: 1em;
}

.text-center {
    text-align: center;
}
p {
    text-align: justify;
    a {
        text-decoration: none;
        color: $defaultText;
        position: relative;
        z-index: 5;
    }
    a:hover {
        text-decoration: underline;
    }
    .smaller {
        font-size: 0.7em;
    }
    .larger { 
        font-size: 1.1em;
    }
}
p.center {
    text-align: center;
}
#logo {
    width: 50%; 
    margin: 0 auto;
    padding: 2rem;
}
nav.nav {
    display: inline-block;
    width: 100%;
    padding-top: 1.3em;
    ul {
        padding: 0px;
        list-style-type: none;
        width: 100%;
        margin: 0px;
        position: relative;
        z-index: 5;
        
        li {
            float: left;
            width: 33.32%;
            a {
                text-align: center;
                font-weight: normal;
                color: $default;
                text-decoration: none;
                font-size: 3em;
                display: block;
                margin-left: 0.3em;
                margin-right: 0.3em;
                
                span {
                    display: none;
                }
                 
            }
            

            a:hover, a.active {
                color: $defaultAccent;
            }
        }
        li:first-child {
            a {
                text-align: right;
            }
        }
        li:last-child {
            a {        
                text-align: left;
                margin-left: 0.6em;        
                margin-right: 0;
            }
        }
        li:first-child {
            a {                
                margin-right: 0.6em;
                margin-left: 0px;
            }
        }
    }
}
.content {
    border: 1px solid $secondaryText;
    border-radius: 5px;
    background: $defaultBackground;
}
.nav-content {
    > div {
        border-top: 1px solid $secondaryText;
        display: none;
        margin: 1.3rem 1.3rem;
        padding: 1.3rem 1.3rem 0rem 1.3rem;
        color: $defaultComp;
    }
    > div.active {
        display: block;
    }
    > div p a {
        color: $defaultAccent;
    }
}

.contact-option {
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #FFB032;
    padding: 0.5em;
    margin: 0.3em 0;
    color: #f4f4f4 !important;
    font-size: 0.9em;
    width: 100%;
    box-sizing: border-box;

    > span {
        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;
        color: #f4f4f4;
        text-align: left;
        white-space: pre-line;
        font-family: monospace;

        transition-duration: 250ms;
        transition-property: height;
        height: 0em;

        > span {
            display: inline-block;
            padding: 0;
        }
        pre {
            margin:0.8em 0em;
        }
    }
}
.contact-option:hover {
    text-decoration: none;
} 

.contact-option:hover {
    > span {
        height: 6em;
    } 
}
    
       

.footer {    
    max-width: 30em;
    padding: 2em;
    small {
        padding-top: 0.5em;
    }
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    div {
        width: 33.3%;
        //padding: 0.5em 3.5em;
        padding: 0.5em 0.9em;
        img {
            width: 100%;
        }
    }
}

@media all and (min-width: 575px) {
    .flex-container {
        div {
            padding: 0.5em 2.5em;
        }
    }
}
@media all and (min-width: 768px) {
    .flex-container {
        div {
            padding: 0.5em 3.5em;
        }
    }
}

@media all and (max-width: 768px) {
    html {
        font-size: 0.8em;
    }
    p {
        font-size: 1.1em;
    }
}
@media all and (max-width: 414px) {
    .footer {
        .separator {
            display: none;
        }
        span, small {
            display: block;
        }
    }
    #logo {
        width: 75%; 
        padding: 1rem;
    }
    pre.code {
        display: none !important;
    }
    #content {
        margin: 0;
    }
}
@media all and (max-width: 1400px) {
    body {
        background: #212121;
    }
    #content {
        background: none;
    }

    pre.code {
        &.code3 {
            display: none !important;
        }
        &.code4 {
            display: none !important;
        }
        &.code5 {
            display: none !important;
        }
        &.code6 {
            display: none !important;
        }
    }

}

@media all and (min-width: 1400px) {
    pre.code {
        &.code1 {
            top: -27px;
            left: 40px;
        }
        &.code2 {
            
        }
        &.code3 {
            top: 77px;            
            left: 77%;
        }
        &.code4 {
            top: 0px;
            right: -135px;
            font-size: 2em;
        }
        &.code5 {
            top: 167px;
            left: 70px;
        }
        &.code6 {
            left: -100px;
            top: 200px;
            font-size: 2em;
        }
    }

}
